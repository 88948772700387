/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-07-06 17:18:28
 * @LastTime: 2022-11-11 11:54:18
 * @LastAuthor: mengllingshuai menglingshuai@busybiz.cn
 * @FilePath: /jurllyshe-nuxt/store/address.js
 * @Description: Do not Description
 */
export const state = () => ({
  addressInfo: {},
  billingAddressInfo: {},
});
export const getters = {
  addressInfo(state) {
    return state.addressInfo;
  },
  billingAddressInfo(state) {
    return state.billingAddressInfo;
  },
};
export const mutations = {
  setAddressInfo(state, data) {
    state.addressInfo = data;
  },
  setBillingAddressInfo(state, data) {
    state.billingAddressInfo = data;
  },
};
export const actions = {};
