//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from "vuex";
import activeDialog from "./activeDialog"; //活动弹窗js
import base from "./base"; //活动弹窗js
export default {
  data() {
    return {
      includeArr: ["IndexPage", "productList", "productsDetail"], // 需要缓存的组件名数组,要在组件中加上对应的name
      popoupData: null,
    };
  },
  mixins: [activeDialog, base],
  computed: {
    ...mapGetters("user", ["isLogin"]),
    // ...mapGetters("cart", ["cartTotal", "popoupInfo"]),
  },
  methods: {
    // ...mapActions("cart", ["getCartList"]),
    ...mapMutations("cart", ["setPopoupInfo"]),
  },
};
