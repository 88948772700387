/*
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-04-12 09:24:58
 * @LastAuthor: liudanli 565478958@qq.com
 * @LastTime: 2023-04-28 13:33:14
 * @FilePath: \unicew-nuxt\middleware\popoup.js
 * @Description: Do not Description
 */

export default async function ({ app, store, route }) {
  let popoupCookie = app.$cookies.get("popoupManageExpTime");
  // let userInfo = app.$cookies.get("userInfo");
  if (!popoupCookie) {
    let params = {
      pos_uri: route.path,
      query_param: route.fullPath.replace("/?", ""),
    };
    let { code, data } = await app.$popoupManage(params);
    if (code == 200000) {
      store.commit("cart/setPopoupInfo", data.popup);
      app.$cookies.set("popoupManageExpTime", Date.now(), {
        maxAge: 60 * 5,
      });
    }
  }
}
