//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 父辈向子辈传参
  },
  data() {
    return {
      email: "",
      navList: [
        {
          title: "About Us",
          isShow: false,
          items: [
            {
              title: "about us",
              link: "https://www.unice.com/about-us",
            },
            {
              title: "retail stores",
              link: "https://www.unice.com/retail-stores",
            },
            {
              title: "fashion blogs",
              link: "https://www.unice.com/blog/",
            },
            {
              title: "accessibility",
              link: "https://m.unice.com/accessibility",
            },
            {
              title: "safety & qulity",
              link: "https://m.unice.com/safety-and-quality",
            },
          ],
        },
        {
          title: "Need Help",
          isShow: false,
          items: [
            {
              title: "how to",
              link: "https://m.unice.com/howto",
            },
            {
              title: "shipment & payment",
              link: "https://www.unice.com/shipment-payment",
            },
            {
              title: "return policy",
              link: "https://m.unice.com/return-policy",
            },
            {
              title: "track order",
              link: "https://m.unice.com/track-order",
            },
            {
              title: "find hair stylist",
              link: "https://m.unice.com/unice-hair-stylist-arrange",
            },
          ],
        },
        {
          title: "Contact Us",
          isShow: false,
          items: [
            {
              title: "Support@Unice.Com",
              link: "",
            },
            {
              title: "Whatsapp：+8617639072910",
              link: "",
            },
            {
              title: "+1 (626) 782-4321",
              link: "",
            },
            {
              title: "APP Cut Extra $3 Off",
              link: "",
            },
          ],
        },
      ],
      shareList: [
        {
          icon: "icon-facebook_box_fill",
          link: "https://www.facebook.com/velvetbyunice",
          type: "facebook",
        },
        {
          icon: "icon-pinterest_fill",
          link: "https://www.pinterest.com/unicecom/",
          type: "pinterest",
        },
        {
          icon: "icon-instagram-fill",
          link: "https://www.instagram.com/velvethairextension/",
          type: "instagram",
        },
        {
          icon: "icon-youtube-fill",
          link: "https://www.youtube.com/c/UniceHair",
          type: "youtube",
        },
        {
          icon: "icon-twitter-fill",
          link: "https://twitter.com/unicehair",
          type: "twitter",
        },
        {
          icon: "icon-twitter-fill-1",
          link: "https://www.tiktok.com/@velvethair01",
          type: "tiktok",
        },
      ],
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
  },
  methods: {
    // 方法

    submit() {
      try {
        this.$validator.validateAll().then((valid) => {
          if (valid == true) {
            this.newsletterHandler();
          }
        });
      } catch (error) {
        throw new Error("底部订阅报错" + error);
      }
    },

    async newsletterHandler() {
      let { code, message } = await this.$newsletter({
        email: this.email,
      });
      if (code == 200000) {
        this.$toast(message);

        this.googleCustomeEventHanlder("subscribe");
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-04-11 09:18:43
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 获取底部信息
     * @return {*}
     * @Description: Do not Description
     */
    async getFooterInfo() {
      try {
        let { code, data } = await this.$getSystemConfigInfo({
          module: "system_other_configs",
        });
        if (code == 200000) {
          console.log(data, "footer,footer");
          this.navList.map((item) => {
            if (item.title == "About Us") {
              item.items = data.footerAboutUs;
            } else if (item.title == "Need Help") {
              item.items = data.footerNeedHelp;
            } else if (item.title == "Contact Us") {
              item.items = data.footerContactUs;
            }
          });
        }
      } catch (error) {
        throw new Error("获取底部媒体链接" + error);
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
    this.getFooterInfo();
    // await import("~/plugins/custormerS.js");
  },
  watch: {
    // 监测变化
  },
};
