import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=9d276fe6&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=9d276fe6&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d276fe6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/From/input.vue').default,SlideToggle: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/SlideToggle.vue').default})
