export const Tabs = () => import('../../pageWap/components/Design/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const Input = () => import('../../pageWap/components/From/input.vue' /* webpackChunkName: "components/input" */).then(c => wrapFunctional(c.default || c))
export const Select = () => import('../../pageWap/components/From/select.vue' /* webpackChunkName: "components/select" */).then(c => wrapFunctional(c.default || c))
export const CheckoutRetention = () => import('../../pageWap/components/Pop/CheckoutRetention.vue' /* webpackChunkName: "components/checkout-retention" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../pageWap/components/Pop/login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const NewCustomer = () => import('../../pageWap/components/Pop/newCustomer.vue' /* webpackChunkName: "components/new-customer" */).then(c => wrapFunctional(c.default || c))
export const Register = () => import('../../pageWap/components/Pop/register.vue' /* webpackChunkName: "components/register" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../pageWap/components/Toast/toast.vue' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const AddressBookDialog = () => import('../../pageWap/components/AddressBookDialog.vue' /* webpackChunkName: "components/address-book-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdressStatusDialog = () => import('../../pageWap/components/AdressStatusDialog.vue' /* webpackChunkName: "components/adress-status-dialog" */).then(c => wrapFunctional(c.default || c))
export const BlurSlotDialog = () => import('../../pageWap/components/BlurSlotDialog.vue' /* webpackChunkName: "components/blur-slot-dialog" */).then(c => wrapFunctional(c.default || c))
export const BottomDialog = () => import('../../pageWap/components/BottomDialog.vue' /* webpackChunkName: "components/bottom-dialog" */).then(c => wrapFunctional(c.default || c))
export const Btn = () => import('../../pageWap/components/Btn.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const CartItemList = () => import('../../pageWap/components/CartItemList.vue' /* webpackChunkName: "components/cart-item-list" */).then(c => wrapFunctional(c.default || c))
export const CommonDialog = () => import('../../pageWap/components/CommonDialog.vue' /* webpackChunkName: "components/common-dialog" */).then(c => wrapFunctional(c.default || c))
export const CustomerReviewImg = () => import('../../pageWap/components/CustomerReviewImg.vue' /* webpackChunkName: "components/customer-review-img" */).then(c => wrapFunctional(c.default || c))
export const CustomerReviews = () => import('../../pageWap/components/CustomerReviews.vue' /* webpackChunkName: "components/customer-reviews" */).then(c => wrapFunctional(c.default || c))
export const EmptyListData = () => import('../../pageWap/components/EmptyListData.vue' /* webpackChunkName: "components/empty-list-data" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../pageWap/components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HeadNav = () => import('../../pageWap/components/HeadNav.vue' /* webpackChunkName: "components/head-nav" */).then(c => wrapFunctional(c.default || c))
export const HeadTitle = () => import('../../pageWap/components/HeadTitle.vue' /* webpackChunkName: "components/head-title" */).then(c => wrapFunctional(c.default || c))
export const HomeScreenDialog = () => import('../../pageWap/components/HomeScreenDialog.vue' /* webpackChunkName: "components/home-screen-dialog" */).then(c => wrapFunctional(c.default || c))
export const LoginDialog2 = () => import('../../pageWap/components/LoginDialog2.vue' /* webpackChunkName: "components/login-dialog2" */).then(c => wrapFunctional(c.default || c))
export const LqRadio = () => import('../../pageWap/components/LqRadio.vue' /* webpackChunkName: "components/lq-radio" */).then(c => wrapFunctional(c.default || c))
export const LqSwitch = () => import('../../pageWap/components/LqSwitch.vue' /* webpackChunkName: "components/lq-switch" */).then(c => wrapFunctional(c.default || c))
export const More = () => import('../../pageWap/components/More.vue' /* webpackChunkName: "components/more" */).then(c => wrapFunctional(c.default || c))
export const OptionsDialog = () => import('../../pageWap/components/OptionsDialog.vue' /* webpackChunkName: "components/options-dialog" */).then(c => wrapFunctional(c.default || c))
export const OptionsProductDialog = () => import('../../pageWap/components/OptionsProductDialog.vue' /* webpackChunkName: "components/options-product-dialog" */).then(c => wrapFunctional(c.default || c))
export const OrderProductDialog = () => import('../../pageWap/components/OrderProductDialog.vue' /* webpackChunkName: "components/order-product-dialog" */).then(c => wrapFunctional(c.default || c))
export const Pay = () => import('../../pageWap/components/Pay.vue' /* webpackChunkName: "components/pay" */).then(c => wrapFunctional(c.default || c))
export const PreviewGalleryImgDialog = () => import('../../pageWap/components/PreviewGalleryImgDialog.vue' /* webpackChunkName: "components/preview-gallery-img-dialog" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../pageWap/components/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductsListTemplate = () => import('../../pageWap/components/ProductsListTemplate.vue' /* webpackChunkName: "components/products-list-template" */).then(c => wrapFunctional(c.default || c))
export const RegisterDialog = () => import('../../pageWap/components/RegisterDialog.vue' /* webpackChunkName: "components/register-dialog" */).then(c => wrapFunctional(c.default || c))
export const ShopCartDialog = () => import('../../pageWap/components/ShopCartDialog.vue' /* webpackChunkName: "components/shop-cart-dialog" */).then(c => wrapFunctional(c.default || c))
export const SlideMiddleSlotDialog = () => import('../../pageWap/components/SlideMiddleSlotDialog.vue' /* webpackChunkName: "components/slide-middle-slot-dialog" */).then(c => wrapFunctional(c.default || c))
export const SlideSlotDialog = () => import('../../pageWap/components/SlideSlotDialog.vue' /* webpackChunkName: "components/slide-slot-dialog" */).then(c => wrapFunctional(c.default || c))
export const SlideToggle = () => import('../../pageWap/components/SlideToggle.vue' /* webpackChunkName: "components/slide-toggle" */).then(c => wrapFunctional(c.default || c))
export const Star = () => import('../../pageWap/components/Star.vue' /* webpackChunkName: "components/star" */).then(c => wrapFunctional(c.default || c))
export const Swiper = () => import('../../pageWap/components/Swiper.vue' /* webpackChunkName: "components/swiper" */).then(c => wrapFunctional(c.default || c))
export const SwiperBlog = () => import('../../pageWap/components/SwiperBlog.vue' /* webpackChunkName: "components/swiper-blog" */).then(c => wrapFunctional(c.default || c))
export const SwiperProduct = () => import('../../pageWap/components/SwiperProduct.vue' /* webpackChunkName: "components/swiper-product" */).then(c => wrapFunctional(c.default || c))
export const UserWishList = () => import('../../pageWap/components/UserWishList.vue' /* webpackChunkName: "components/user-wish-list" */).then(c => wrapFunctional(c.default || c))
export const ViewMoreBtn = () => import('../../pageWap/components/ViewMoreBtn.vue' /* webpackChunkName: "components/view-more-btn" */).then(c => wrapFunctional(c.default || c))
export const BlogDetail = () => import('../../pageWap/components/blogDetail.vue' /* webpackChunkName: "components/blog-detail" */).then(c => wrapFunctional(c.default || c))
export const CmsPage = () => import('../../pageWap/components/cmsPage.vue' /* webpackChunkName: "components/cms-page" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../pageWap/components/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const LoginDialog = () => import('../../pageWap/components/loginDialog.vue' /* webpackChunkName: "components/login-dialog" */).then(c => wrapFunctional(c.default || c))
export const ProductDetail = () => import('../../pageWap/components/productDetail.vue' /* webpackChunkName: "components/product-detail" */).then(c => wrapFunctional(c.default || c))
export const ProductList = () => import('../../pageWap/components/productList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c))
export const ProductListEffect = () => import('../../pageWap/components/productListEffect.vue' /* webpackChunkName: "components/product-list-effect" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
