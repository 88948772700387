import { render, staticRenderFns } from "./normal.vue?vue&type=template&id=35b31db4&"
import script from "./normal.vue?vue&type=script&lang=js&"
export * from "./normal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeadNav: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/HeadNav.vue').default,Footer: require('/data/jenkins-data/workspace/Unicew-mobile-pro/pageWap/components/Footer.vue').default})
