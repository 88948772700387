/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-07-06 17:18:28
 * @LastTime: 2023-02-16 15:39:33
 * @LastAuthor: liguohua 2490322461@qq.com
 * @FilePath: \unicew-nuxt\store\productList.js
 * @Description: Do not Description
 */
export const state = () => ({
  productListTotalProduct: 0,
  productListPageInfo: { page: 1, limit: 20 },
  productListData: [],
  productListBreadcrumbInfo: [],
  productListCategoryInfo: null,
  productListPageScroll: null,
  productAvailableSortBy: [],
  productListCategoryId: null,
  productDefaultSortBy: null,
  productListGtm: [],
  productListMoreData: true,
  productListPushAd: true,
  productListEffectData: [], //before afeter data
  productListEffectCategroyInfo: null, //before afeter data
  productListCategory: null,
});
export const getters = {
  productListTotalProduct(state) {
    return state.productListTotalProduct;
  },
  productListPageInfo(state) {
    return state.productListPageInfo;
  },
  productListData(state) {
    return state.productListData;
  },
  productListPageScroll(state) {
    return state.productListPageScroll;
  },
  productListBreadcrumbInfo(state) {
    return state.productListBreadcrumbInfo;
  },
  productListCategoryInfo(state) {
    return state.productListCategoryInfo;
  },
  productAvailableSortBy(state) {
    return state.productAvailableSortBy;
  },
  productDefaultSortBy(state) {
    return state.productDefaultSortBy;
  },
  productListCategoryId(state) {
    return state.productListCategoryId;
  },
  productListGtm(state) {
    return state.productListGtm;
  },
  productListMoreData(state) {
    return state.productListMoreData;
  },
  productListPushAd(state) {
    return state.productListPushAd;
  },
  productListEffectData(state) {
    return state.productListEffectData;
  },
  productListEffectCategroyInfo(state) {
    return state.productListEffectCategroyInfo;
  },
  productListCategory(state) {
    return state.productListCategory;
  },
};
export const mutations = {
  setProductListTotalProduct(state, data) {
    state.productListTotalProduct = data;
  },
  setProductListPageInfo(state, data) {
    state.productListPageInfo = data;
  },
  setProductListData(state, { data, isAdd, isUnshift }) {
    if (isUnshift) {
      state.productListData = [...data, ...state.productListData];
    } else {
      console.log(state.productListData, "原有的");
      state.productListData = isAdd ? [...state.productListData, ...data] : [...data];
    }
  },
  setProductListPageScroll(state, data) {
    state.productListPageScroll = data;
  },
  setProductListBreadcrumbInfo(state, data) {
    state.productListBreadcrumbInfo = data;
  },
  setProductListCategoryInfo(state, data) {
    state.productListCategoryInfo = data;
  },
  setProductAvailableSortBy(state, data) {
    state.productAvailableSortBy = data;
  },
  setProductDefaultSortBy(state, data) {
    state.productDefaultSortBy = data;
  },
  setProductListCategoryId(state, data) {
    state.productListCategoryId = data;
  },
  setProductListGtm(state, data) {
    state.productListGtm = data;
  },
  setProductListMoreData(state, data) {
    state.productListMoreData = data;
  },
  setProductListPushAd(state, data) {
    state.productListPushAd = data;
  },
  setProductListEffectData(state, data) {
    state.productListEffectData = data;
  },
  setProductListEffectCategroyInfo(state, data) {
    state.productListEffectCategroyInfo = data;
  },
  setProductListCategory(state, data) {
    state.productListCategory = data;
  },
};
export const actions = {};
