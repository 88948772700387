/*
 * @Author: liudanli 565478958@qq.com
 * @Date: 2023-02-11 09:43:12
 * @LastTime: 2023-04-28 14:38:18
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\store\user.js
 * @Description: Do not Description
 */
import LoginService from "~~/utils/login";
export const state = () => ({
  isLogin: false,
  pendingOrder: 0,
  points: null,
  wishlist: 0,
  coupon: 0,
  thridAuthInfo: {},
  snsLogin: 3,
  currency: {
    currencyTo: "USD",
    symbol: "$",
  },
  userInfo: null,
  topAdShow: true,
});
export const getters = {
  isLogin(state) {
    return state.isLogin;
  },
  getIsLogin(state) {
    return state.isLogin;
  },
  getSnsLogin(state) {
    return state.snsLogin;
  },
  currency(state) {
    return state.currency;
  },
  getUserInfo(state) {
    return state.userInfo;
  },
  wishlist(state) {
    return state.wishlist;
  },
  coupon(state) {
    return state.coupon;
  },
  pendingOrder(state) {
    return state.pendingOrder;
  },
  getTopAdShow(state) {
    return state.topAdShow;
  },
  getThridAuthInfo() {
    return state.thridAuthInfo;
  },
};
export const mutations = {
  setIsLogin(state, data) {
    if (state.isLogin != data) {
      console.log("buxiangdeng");
    }
    state.isLogin = data;
  },
  setSnsLogin(state, data) {
    state.snsLogin = data;
  },
  setUserInfo(state, data) {
    // console.log(data);
    state.userInfo = data;
  },
  setPoints(state, data) {
    state.points = data;
  },
  setUnpaid(state, data) {
    state.pendingOrder = data;
  },
  setWishlist(state, data) {
    state.wishlist = data;
  },
  setCoupon(state, data) {
    state.coupon = data;
  },
  setTopAdShow(state, data) {
    state.topAdShow = data;
  },
  setThridAuthInfo(state, data) {
    state.thridAuthInfo = data;
  },
  setCurrecy(state, data) {
    state.currency = data;
    this.$cookies.set("currency", data.currencyTo);
    this.$cookies.set("currencySymbol", data.symbol);
  },
};
export const actions = {
  //第三方登陆 初始化
  initThridLogin(store) {
    if (process.client) {
      const service = new LoginService(store.dispatch, store.commit);
      service.init("facebook");
      service.init("google");
      window.loginServices = service;
    }
  },
  /**
   * @Date: 2023-02-27 17:57:38
   * @Description: 第三方登陆的操作
   * @param {*} state
   * @param {*} data
   * @return {*}
   */
  thridLoginAction(state, data) {
    const type = data.type;
    console.log(type);
    if (window.loginServices) {
      if (type === "facebook") {
        window.loginServices.facebookCheckLogin();
      } else if (type === "google") {
        window.loginServices.googleClick();
      }
    }
  },
  /**
   * @Date: 2023-02-27 17:58:12
   * @Description: 第三方登陆创建用户信息
   * @param {*} commit
   * @param {*} data
   * @return {*}
   */
  async thridLoginInterface({ commit }, data) {
    const types = {
      google: 0,
      facebook: 1,
      apple: 2,
    };
    const params = {
      ...data.params,
      sns_login: types[data.params.sns_login],
    };
    const res = await this.$thridLogin(params);
    this.$cookies.set("userInfo", res.data, {
      maxAge: 60 * 60 * 24 * 120,
      path: "/",
    });
    commit("setThridAuthInfo", data.response);
    commit("setSnsLogin", types[data.params.sns_login]);
    commit("setIsLogin", true);
    this.$router.back();
    this.googleCustomeEventHanlder("login_success", "Register/Login", "click", "login_success");
  },
  //用户中心首页获取用户的所有信息
  async getUserInfoAll({ commit }) {
    let res = await this.$getUserInfoAll();
    console.log(res.data, "store getuserinfo");
    commit("setUserInfo", res.data.info);
    commit("setPoints", res.data.info.point ? res.data.info.point : 0);
    commit("setUnpaid", res.data.pendingOrder ? res.data.pendingOrder : 0);
    commit("setWishlist", res.data.wishlistCount ? res.data.wishlistCount : 0);
    commit("setCoupon", res.data.couponCount ? res.data.couponCount : 0);
    commit("setIsLogin", true);
  },
  async getUserInfoData({ commit }) {
    let res = await this.$getUserInfo();
    console.log(res, "userinfodata");
    commit("setUserInfo", res.data.info);
    commit("setIsLogin", true);
  },
  removeLogin({ commit }) {
    this.$cookies.remove("userInfo");
    commit("setUserInfo", null);
    commit("setPoints", 0);
    commit("setIsLogin", false);
  },
  async loginOut({ commit }) {
    try {
      let res = await this.$logOut();
      // console.log(res);
      if (res.code === 200000) {
        this.$cookies.remove("userInfo");
        commit("setUserInfo", null);
        commit("setPoints", 0);
        commit("setIsLogin", false);
        // commit("setWishList", []);
      }
    } catch (error) {
      console.log(error);
    }
  },
};
