/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-05-23 08:35:58
 * @LastTime: 2023-04-24 17:13:51
 * @LastAuthor: liguohua 2490322461@qq.com
 * @FilePath: \unicew-nuxt\store\index.js
 * @Description: Do not Description
 */
export const state = () => ({
  pageInfoUrl: null,
  productInfo: {},
  guestToken: null,
  searchProductListData: [],
  searchProductDataTotal: 0,
  searchProductListPageScroll: 0,
  productAvailableSortBy: [],
  seoInfo: null,
  orderUserActivity: true,
  customMarkList: [
    // 用户来源标识
    "gclid",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "msclkid",
    "fbclid",
    "fclid",
    "rfsn",
    "sscid",
    "mc_cid",
    "mc_eid",
    "wbraid",
    "gbraid",
    "irgwc",
    "irclickid",
    "irpid",
    "irmpname",
    "cjevent",
    "cm_mmc",
    "cjdata",
    "sms_clicked_window",
    "sms_received_window",
    "email_opened_window",
    "wtbap",
    "awc",
  ],
  pageType: "", //当routername 为all时存一下当前页面类型
});
export const getters = {
  productInfo(state) {
    return state.productInfo;
  },
  guestToken(state) {
    return state.guestToken;
  },
  pageInfoUrl(state) {
    return state.pageInfoUrl;
  },
  searchProductListData(state) {
    return state.searchProductListData;
  },
  searchProductDataTotal(state) {
    return state.searchProductDataTotal;
  },
  searchProductListPageScroll(state) {
    return state.searchProductListPageScroll;
  },
  seoInfo(state) {
    return state.seoInfo;
  },
  customMarkList(state) {
    return state.customMarkList;
  },
  pageType(state) {
    return state.pageType;
  },
  orderUserActivity(state) {
    return state.orderUserActivity;
  },
};
export const mutations = {
  setProductInfo(state, data) {
    state.productInfo = data;
  },
  setGuestToken(state, data) {
    state.guestToken = data;
  },
  setPageInfoUrl(state, data) {
    state.pageInfoUrl = data;
  },
  setSearchProductListData(state, { data, isAdd }) {
    if (isAdd) {
      state.searchProductListData = [...state.searchProductListData, ...data];
    } else {
      state.searchProductListData = [...data];
    }
  },
  setSearchProductDataTotal(state, data) {
    state.searchProductDataTotal = data;
  },
  setSearchProductListPageScroll(state, data) {
    state.searchProductListPageScroll = data;
  },
  setSeoInfo(state, data) {
    state.seoInfo = data;
  },
  setPageType(state, data) {
    state.pageType = data;
  },
  setOrderUserActivity(state, data) {
    state.orderUserActivity = data;
  },
};
export const actions = {};
