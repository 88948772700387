//
//
//
//
//
//
//
//
//
//
//
//
//

import base from "./base"; //活动弹窗js
export default {
  name: "default",
  data() {
    return {
      includeArr: ["catagory"], // 需要缓存的组件名数组,要在组件中加上对应的name
    };
  },
  mixins: [base],
  components: {},
};
