/*
 * @Author: liguohua 2490322461@qq.com
 * @Date: 2023-04-24 09:28:45
 * @LastAuthor: liudanli 565478958@qq.com
 * @LastTime: 2023-04-28 14:27:19
 * @FilePath: \unicew-nuxt\layouts\base.js
 * @Description: Do not Description
 */
import throttle from "~~/utils/index.js";
export default {
  data() {
    return {
      fnScroll: () => {},
    };
  },
  watch: {
    $route: {
      handler(val) {
        if (process.client && val.name != "all") {
          let dataLayer = window.dataLayer || [];
          dataLayer.push({
            event: "dle_pageload",
            page_group: val.name,
            user_id: this.$cookies.get("userInfo")?.user?.id || "",
            dlv_cu: this.currencySymbol,
          });
        }
      },
      deep: true,
      immediate: true,
    },
    isLogin: {
      handler: function (newVal, oldVal) {
        console.log(newVal, oldVal, "需要再次执行");
        if (newVal != oldVal) {
          this.getPopoup();
        }
      },
    },
  },
  created() {
    // 实例被创建之后执行代码
  },
  methods: {
    //滚动处理函数
    fnHandleScroll() {
      this.proxyGtmContainImage(document.querySelectorAll(".dataLayerTriggerEvent,.dataLayerTriggerProduct"));
    },
    /**
     * @Author: liudanli 565478958@qq.com
     * @Date: 2023-04-28 14:19:53
     * @LastAuthor: liudanli 565478958@qq.com
     * @description: 请求弹框数据
     * @return {*}
     * @Description: Do not Description
     */
    async getPopoup() {
      let params = {
        pos_uri: this.$route.path,
        query_param: this.$route.fullPath.replace("/?", ""),
      };
      let { code, data } = await this.$popoupManage(params);
      if (code == 200000) {
        this.setPopoupInfo(data.popup);
        console.log(data, "再次请求弹框数据");
        this.$cookies.set("popoupManageExpTime", Date.now(), {
          maxAge: 60 * 5,
        });
      }
    },
  },
  computed: {},
  mounted() {
    if (process.client) {
      // event_category：自定义参数，对应UA事件类别
      // event_action：自定义参数，对应UA事件操作
      // event_label：自定义参数，对应UA事件标签
      // creative_name：素材名称，传入图片素材名称，如 xxx_01.jpg
      // creative_slot：素材位置，传入素材所在位置序列数，如 1
      // promotion_id：推广活动ID，传入推广活动所在页面位置，如 Top Banner
      // promotion_name：推广活动名称，传入推广活动名称 <alt="xxxxx">，如 PC 01 20211129
      // items：传入推广活动信息，必须
      // / *
      // item_id：传值同promotion_id，必须
      // item_name：传值同promotion_name，必须
      // * /
      //gtm 点击图片处理
      window.addEventListener("click", (e) => {
        if (e.target.className && e.target.className.indexOf("dataLayerTriggerEvent") > -1) {
          let param = {};
          param = {
            eventType: "click",
            event_category: this.$route.name,
            event_label: e.target?.dataset?.label,
            promotion_name: e.target?.dataset?.promotion_name,
            promotion_id: e.target?.dataset?.promotion_id,
            creative_name: e.target?.dataset?.creative_name,
            creative_slot: e.target?.dataset?.solt,
          };
          this.$googleGraphicEvent(param);
        }
      });

      //gtm 滚动图片 节流函数 可视区域内触发
      this.fnScroll = throttle(this.fnHandleScroll, 1000);
      window.addEventListener("scroll", () => {
        this.fnScroll();
      });
    }
  },
};
