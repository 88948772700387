//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    // 父辈向子辈传参
    redirect: {
      type: String,
      default: () => "",
    },
  },
  // name: "HeadNav",
  data() {
    return {
      firstSwiperOption: {
        autoplay: {
          delay: 10000,
        },
        speed: 1000,
        loop: false,
        autoplayDisableOnInteraction: false,
        on: {},
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,
        spaceBetween: 10,
      },
      topInfo: {},
      topBanner: null,
      countdownStr: "",
    };
  },
  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
    ...mapGetters("cart", ["cartTotal"]),
    ...mapGetters("user", ["isLogin", "getTopAdShow"]),
  },
  components: {
    // 组件的引用
  },
  methods: {
    ...mapMutations("user", ["setTopAdShow"]),
    // 方法
    handlerUser() {
      // console.log(this.$router);
      if (this.redirect) {
        this.$router.push({ path: this.isLogin ? "/user" : `/login?redirect=${this.redirect}` });
        return;
      }
      this.$router.push({ path: this.isLogin ? "/user" : "/login" });
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-16 10:33:29
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 获取顶部信息
     * @return {*}
     * @Description: Do not Description
     */
    async getHomeTopHandler() {
      try {
        let topInfoRes = await this.$homeTop();
        this.topInfo = topInfoRes.data.configs;
        this.topBanner = this.topInfo.topBanner;
        console.log(this.topBanner);
        // if (this.topInfo.topEndTime) {
        //   this.countdown();
        // }
      } catch (error) {
        throw new Error("获取顶部信息" + error);
      }
    },
    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-16 10:38:22
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 关闭顶部广告
     * @return {*}
     * @Description: Do not Description
     */
    closeAd() {
      try {
        this.setTopAdShow(false);
      } catch (error) {
        throw new Error("关闭顶部广告" + error);
      }
    },

    /**
     * @Author: liguohua 2490322461@qq.com
     * @Date: 2023-03-16 10:39:13
     * @LastAuthor: liguohua 2490322461@qq.com
     * @description: 倒计时
     * @return {*}
     * @Description: Do not Description
     */
    async countdown() {
      try {
        if (this.topInfo.topEndTime) {
          let getSeverTime = await this.$serverTime();
          let severDate = this.$dayjs(getSeverTime.data.now).valueOf();
          let endTime = this.$dayjs(this.topInfo.topEndTime).valueOf();

          this.tool.countDown(endTime - severDate, 100, (obj) => {
            this.countdownStr = `${obj.h} H ${obj.m} M ${obj.s} S ${obj.ms / 10 ? obj.ms / 10 : "00"} `;
          });
        }
      } catch (error) {
        throw new Error("顶部倒计时" + error);
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
    console.log(this.getTopAdShow);
    this.getHomeTopHandler();
  },
  watch: {
    // 监测变化
  },
};
