import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5d3a008b = () => interopDefault(import('../pageWap/pages/403.vue' /* webpackChunkName: "pages/403" */))
const _5d48180c = () => interopDefault(import('../pageWap/pages/404.vue' /* webpackChunkName: "pages/404" */))
const _56390081 = () => interopDefault(import('../pageWap/pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _c1b0f302 = () => interopDefault(import('../pageWap/pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _446c6e46 = () => interopDefault(import('../pageWap/pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _2fce8ecc = () => interopDefault(import('../pageWap/pages/forgotpassword.vue' /* webpackChunkName: "pages/forgotpassword" */))
const _18ff9ebd = () => interopDefault(import('../pageWap/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4dcab602 = () => interopDefault(import('../pageWap/pages/register.vue' /* webpackChunkName: "pages/register" */))
const _873833f2 = () => interopDefault(import('../pageWap/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _8fb1452c = () => interopDefault(import('../pageWap/pages/user/index.vue' /* webpackChunkName: "pages/user/index" */))
const _be14fa82 = () => interopDefault(import('../pageWap/pages/activity/new-customer.vue' /* webpackChunkName: "pages/activity/new-customer" */))
const _5049f62e = () => interopDefault(import('../pageWap/pages/activity/velvet-hair-store-grand-opening-event.vue' /* webpackChunkName: "pages/activity/velvet-hair-store-grand-opening-event" */))
const _6eea7553 = () => interopDefault(import('../pageWap/pages/order/confirm.vue' /* webpackChunkName: "pages/order/confirm" */))
const _02daa4ce = () => interopDefault(import('../pageWap/pages/order/payment.vue' /* webpackChunkName: "pages/order/payment" */))
const _3517aa50 = () => interopDefault(import('../pageWap/pages/order/payWait.vue' /* webpackChunkName: "pages/order/payWait" */))
const _f7ca5790 = () => interopDefault(import('../pageWap/pages/search/result.vue' /* webpackChunkName: "pages/search/result" */))
const _2a445d0f = () => interopDefault(import('../pageWap/pages/user/address/index.vue' /* webpackChunkName: "pages/user/address/index" */))
const _fae4a67e = () => interopDefault(import('../pageWap/pages/user/coupon/index.vue' /* webpackChunkName: "pages/user/coupon/index" */))
const _58df05b6 = () => interopDefault(import('../pageWap/pages/user/logout/index.vue' /* webpackChunkName: "pages/user/logout/index" */))
const _db0bad84 = () => interopDefault(import('../pageWap/pages/user/points/index.vue' /* webpackChunkName: "pages/user/points/index" */))
const _9bd4e72a = () => interopDefault(import('../pageWap/pages/user/setting/index.vue' /* webpackChunkName: "pages/user/setting/index" */))
const _6fb7e0c0 = () => interopDefault(import('../pageWap/pages/user/wishlist/index.vue' /* webpackChunkName: "pages/user/wishlist/index" */))
const _0895a790 = () => interopDefault(import('../pageWap/pages/order/components/address.vue' /* webpackChunkName: "pages/order/components/address" */))
const _43ae213e = () => interopDefault(import('../pageWap/pages/user/order/unpaid/index.vue' /* webpackChunkName: "pages/user/order/unpaid/index" */))
const _b3083bb6 = () => interopDefault(import('../pageWap/pages/user/setting/info.vue' /* webpackChunkName: "pages/user/setting/info" */))
const _2ab91bdc = () => interopDefault(import('../pageWap/pages/user/setting/password.vue' /* webpackChunkName: "pages/user/setting/password" */))
const _6bea3209 = () => interopDefault(import('../pageWap/pages/user/address/components/AddAddress.vue' /* webpackChunkName: "pages/user/address/components/AddAddress" */))
const _26d1c575 = () => interopDefault(import('../pageWap/pages/user/order/detail/_id.vue' /* webpackChunkName: "pages/user/order/detail/_id" */))
const _7572a508 = () => interopDefault(import('../pageWap/pages/user/order/review/_id.vue' /* webpackChunkName: "pages/user/order/review/_id" */))
const _7045ee0c = () => interopDefault(import('../pageWap/pages/blog/category/_name.vue' /* webpackChunkName: "pages/blog/category/_name" */))
const _2afec293 = () => interopDefault(import('../pageWap/pages/user/order/_status/_id.vue' /* webpackChunkName: "pages/user/order/_status/_id" */))
const _015f2f29 = () => interopDefault(import('../pageWap/pages/blog/_id.vue' /* webpackChunkName: "pages/blog/_id" */))
const _4f57fba6 = () => interopDefault(import('../pageWap/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _285b7a9a = () => interopDefault(import('../pageWap/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/403",
    component: _5d3a008b,
    name: "403"
  }, {
    path: "/404",
    component: _5d48180c,
    name: "404"
  }, {
    path: "/blog",
    component: _56390081,
    name: "blog"
  }, {
    path: "/cart",
    component: _c1b0f302,
    name: "cart"
  }, {
    path: "/category",
    component: _446c6e46,
    name: "category"
  }, {
    path: "/forgotpassword",
    component: _2fce8ecc,
    name: "forgotpassword"
  }, {
    path: "/login",
    component: _18ff9ebd,
    name: "login"
  }, {
    path: "/register",
    component: _4dcab602,
    name: "register"
  }, {
    path: "/search",
    component: _873833f2,
    name: "search"
  }, {
    path: "/user",
    component: _8fb1452c,
    name: "user"
  }, {
    path: "/activity/new-customer",
    component: _be14fa82,
    name: "activity-new-customer"
  }, {
    path: "/activity/velvet-hair-store-grand-opening-event",
    component: _5049f62e,
    name: "activity-velvet-hair-store-grand-opening-event"
  }, {
    path: "/order/confirm",
    component: _6eea7553,
    name: "order-confirm"
  }, {
    path: "/order/payment",
    component: _02daa4ce,
    name: "order-payment"
  }, {
    path: "/order/payWait",
    component: _3517aa50,
    name: "order-payWait"
  }, {
    path: "/search/result",
    component: _f7ca5790,
    name: "search-result"
  }, {
    path: "/user/address",
    component: _2a445d0f,
    name: "user-address"
  }, {
    path: "/user/coupon",
    component: _fae4a67e,
    name: "user-coupon"
  }, {
    path: "/user/logout",
    component: _58df05b6,
    name: "user-logout"
  }, {
    path: "/user/points",
    component: _db0bad84,
    name: "user-points"
  }, {
    path: "/user/setting",
    component: _9bd4e72a,
    name: "user-setting"
  }, {
    path: "/user/wishlist",
    component: _6fb7e0c0,
    name: "user-wishlist"
  }, {
    path: "/order/components/address",
    component: _0895a790,
    name: "order-components-address"
  }, {
    path: "/user/order/unpaid",
    component: _43ae213e,
    name: "user-order-unpaid"
  }, {
    path: "/user/setting/info",
    component: _b3083bb6,
    name: "user-setting-info"
  }, {
    path: "/user/setting/password",
    component: _2ab91bdc,
    name: "user-setting-password"
  }, {
    path: "/user/address/components/AddAddress",
    component: _6bea3209,
    name: "user-address-components-AddAddress"
  }, {
    path: "/user/order/detail/:id?",
    component: _26d1c575,
    name: "user-order-detail-id"
  }, {
    path: "/user/order/review/:id?",
    component: _7572a508,
    name: "user-order-review-id"
  }, {
    path: "/blog/category/:name?",
    component: _7045ee0c,
    name: "blog-category-name"
  }, {
    path: "/user/order/:status?/:id?",
    component: _2afec293,
    name: "user-order-status-id"
  }, {
    path: "/blog/:id",
    component: _015f2f29,
    name: "blog-id"
  }, {
    path: "/",
    component: _4f57fba6,
    name: "index"
  }, {
    path: "/*",
    component: _285b7a9a,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
