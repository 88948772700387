/*
 * @Author: mengllingshuai menglingshuai@busybiz.cn
 * @Date: 2022-06-29 16:23:23
 * @LastTime: 2023-04-15 09:09:17
 * @LastAuthor: liudanli 565478958@qq.com
 * @FilePath: \unicew-nuxt\store\cart.js
 * @Description: Do not Description
 */
export const state = () => ({
  cartTotal: null,
  checkoutProducts: [],
  cartList: null,
  cartPriceSubTotal: 0,
  popoupInfoData: null,
});
export const getters = {
  cartTotal(state) {
    return state.cartTotal;
  },
  checkoutProducts(state) {
    return state.checkoutProducts;
  },
  cartList(state) {
    return state.cartList;
  },
  cartPriceSubTotal(state) {
    return state.cartPriceSubTotal;
  },
  popoupInfo(state) {
    return state.popoupInfoData;
  },
};
export const mutations = {
  setCartTotal(state, data) {
    state.cartTotal = data;
  },
  setCheckOutProducts(state, data) {
    state.checkoutProducts = data;
  },
  setCartList(state, data) {
    state.cartList = data;
  },
  setCartPriceSubTotal(state, data) {
    state.cartPriceSubTotal = data;
  },
  setPopoupInfo(state, data) {
    let dataObj = {};
    data.forEach((item) => {
      let key = item.identifier;
      key = key.toLowerCase();
      dataObj[key] = item;
    });
    state.popoupInfoData = dataObj;
  },
};
export const actions = {
  /**
   * @Author: liguohua 2490322461@qq.com
   * @Date: 2023-03-17 11:30:30
   * @LastAuthor: liguohua 2490322461@qq.com
   * @description:
   * @param {*} commit
   * @param {*} flag :是否设置setCheckOutProducts 默认false  用于详情页直接加购 可以跳到结账
   * @return {*}
   * @Description: Do not Description
   */
  getCartList({ commit }, flag = false) {
    this.$getCartList().then(({ data }) => {
      let count = data.quote?.itemsQty ? data.quote.itemsQty : 0;
      commit("setCartTotal", count);
      commit("setCartList", data.items);
      commit("setCartPriceSubTotal", data.quote?.subtotal ? parseFloat(data.quote?.previewSubtotalFormat).toFixed(2) : 0);
      if (flag) {
        commit("setCheckOutProducts", data.items);
      }
      // 老站用户购物车信息同步
      let quote_id = this.$cookies.get("quote_id");
      if (count == 0 && quote_id) {
        this.$pullQuote({ quote_id });
      }
    });
  },
};
